@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  [type=text],
  [type=password],
  [type=email],
  [type=number],
  [type=url],
  [type=date],
  [type=datetime-local],
  [type=month],
  [type=week],
  [type=time],
  [type=search],
  [type=tel],
  [type=checkbox],
  [type=radio],
  select,
  select[multiple],
  textarea {
    @apply border border-theme-fieldBorder bg-theme-fieldBg p-2 rounded-innerBox text-theme-base;
  }
  [type=text]:disabled,
  [type=password]:disabled,
  [type=email]:disabled,
  [type=number]:disabled,
  [type=url]:disabled,
  [type=date]:disabled,
  [type=datetime-local]:disabled,
  [type=month]:disabled,
  [type=week]:disabled,
  [type=time]:disabled,
  [type=search]:disabled,
  [type=tel]:disabled,
  [type=checkbox]:disabled,
  [type=radio]:disabled,
  select:disabled,
  select[multiple]:disabled,
  textarea:disabled {
    @apply border-0 opacity-50;
  }
  [type=text]:focus:not(:disabled), [type=text]:focus-within:not(:disabled),
  [type=password]:focus:not(:disabled),
  [type=password]:focus-within:not(:disabled),
  [type=email]:focus:not(:disabled),
  [type=email]:focus-within:not(:disabled),
  [type=number]:focus:not(:disabled),
  [type=number]:focus-within:not(:disabled),
  [type=url]:focus:not(:disabled),
  [type=url]:focus-within:not(:disabled),
  [type=date]:focus:not(:disabled),
  [type=date]:focus-within:not(:disabled),
  [type=datetime-local]:focus:not(:disabled),
  [type=datetime-local]:focus-within:not(:disabled),
  [type=month]:focus:not(:disabled),
  [type=month]:focus-within:not(:disabled),
  [type=week]:focus:not(:disabled),
  [type=week]:focus-within:not(:disabled),
  [type=time]:focus:not(:disabled),
  [type=time]:focus-within:not(:disabled),
  [type=search]:focus:not(:disabled),
  [type=search]:focus-within:not(:disabled),
  [type=tel]:focus:not(:disabled),
  [type=tel]:focus-within:not(:disabled),
  [type=checkbox]:focus:not(:disabled),
  [type=checkbox]:focus-within:not(:disabled),
  [type=radio]:focus:not(:disabled),
  [type=radio]:focus-within:not(:disabled),
  select:focus:not(:disabled),
  select:focus-within:not(:disabled),
  select[multiple]:focus:not(:disabled),
  select[multiple]:focus-within:not(:disabled),
  textarea:focus:not(:disabled),
  textarea:focus-within:not(:disabled) {
    @apply border-theme-fieldBorder bg-theme-bg;
  }
  [type=text]::placeholder, [type=text].invalid,
  [type=password]::placeholder,
  [type=password].invalid,
  [type=email]::placeholder,
  [type=email].invalid,
  [type=number]::placeholder,
  [type=number].invalid,
  [type=url]::placeholder,
  [type=url].invalid,
  [type=date]::placeholder,
  [type=date].invalid,
  [type=datetime-local]::placeholder,
  [type=datetime-local].invalid,
  [type=month]::placeholder,
  [type=month].invalid,
  [type=week]::placeholder,
  [type=week].invalid,
  [type=time]::placeholder,
  [type=time].invalid,
  [type=search]::placeholder,
  [type=search].invalid,
  [type=tel]::placeholder,
  [type=tel].invalid,
  [type=checkbox]::placeholder,
  [type=checkbox].invalid,
  [type=radio]::placeholder,
  [type=radio].invalid,
  select::placeholder,
  select.invalid,
  select[multiple]::placeholder,
  select[multiple].invalid,
  textarea::placeholder,
  textarea.invalid {
    @apply text-theme-muted;
  }
  select {
    cursor: pointer;
  }
}
@layer components {
  .divider {
    @apply border-dotted border-theme-fieldBorder;
  }
  .divider-b {
    @apply divider border-b;
  }
  .divider-t {
    @apply divider border-t;
  }
  .divider-l {
    @apply divider border-l;
  }
  .divider-r {
    @apply divider border-r;
  }
}
@layer utilities {}
@layer base {
  :root {
    --color-kap-black: 0 0 0;
    --color-kap-white: 255 255 255;
    --color-kap-neon: 225 252 1;
    --color-kap-teal: 37 233 231;
    --color-kap-blue: 45 0 255;
    --color-kap-purple: 132 0 227;
    --color-kap-gray: 38 38 36;
    --color-kap-light-gray: 151 151 148;
    --color-kap-warning: 244 243 0;
    --color-kap-danger: 237 79 79;
    --color-kap-success: 0 231 0;
    --color-text-base: 19 19 22;
    --color-text-muted: 89 89 89;
    --color-text-punched: 19 19 22;
    --color-text-highlighted: 225 252 1;
    --color-bg: 239 239 239;
    --color-bg-muted: 112 112 112;
    --color-container-bg: 255 255 255;
    --color-field-bg: 239 239 239;
    --color-field-border: 19 19 22;
    --color-field-placeholder: 19 19 22;
    --color-primary-cta-text: 19 19 22;
    --color-primary-cta-bg: 225 252 1;
    --color-primary-cta-border: 225 252 1;
    --color-secondary-cta-text: 19 19 22;
    --color-secondary-cta-bg: 225 252 1;
    --color-secondary-cta-border: 225 252 1;
    --color-cancel-cta-text: 236 72 153;
    --color-cancel-cta-border: 236 72 153;
    --color-table-bg: 239 239 239;
    --color-table-text: 19 19 22;
    --color-table-header-bg: 19 19 22;
    --color-table-header-text: 255 255 255;
    --color-lightsaber: 52 171 171;
    --color-success: 7 188 12;
    --color-error: 231 76 60;
    --color-warning: 241 196 15;
    --color-info: 52 152 219;
  }
  .dark {
    --color-text-base: 239 229 210;
    --color-text-muted: 153 140 122;
    --color-text-punched: 255 255 255;
    --color-text-highlighted: 225 252 1;
    --color-bg: 19 19 22;
    --color-bg-muted: 112 112 112;
    --color-container-bg: 38 38 38;
    --color-field-bg: 26 26 26;
    --color-field-border: 112 112 112;
    --color-field-placeholder: 141 130 108;
    --color-primary-cta-text: 19 19 22;
    --color-primary-cta-bg: 225 252 1;
    --color-primary-cta-border: 225 252 1;
    --color-secondary-cta-text: 225 252 1;
    --color-secondary-cta-bg: 38 38 38;
    --color-secondary-cta-border: 225 252 1;
    --color-cancel-cta-text: 236 72 153;
    --color-cancel-cta-border: 236 72 153;
    --color-table-bg: 51 51 51;
    --color-table-text: 239 229 210;
    --color-table-header-bg: 74 74 74;
    --color-table-header-text: 255 255 255;
    --color-lightsaber: 52 171 171;
    --color-success: 87 160 99;
    --color-error: 231 76 60;
    --color-warning: 241 196 15;
    --color-info: 52 152 219;
  }
}
/* --------------------- ===
  FONTS
---------------------------*/
@font-face {
  font-family: "inter";
  src: url("/assets/Inter-VariableFont_slnt_wght.ttf") format("truetype");
  font-weight: 100 1000;
  font-display: swap;
}
@font-face {
  font-family: "Transducer";
  src: url("/assets/transducer-font/TransducerTest-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Transducer";
  src: url("/assets/transducer-font/TransducerTest-Bold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

svg {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.1;
}

body {
  margin: 0;
  @apply bg-theme-bg;
}

[type=text]:focus,
[type=email]:focus,
[type=url]:focus,
[type=password]:focus,
[type=number]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=week]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
}

[type=checkbox]:focus,
[type=radio]:focus {
  box-shadow: none;
}

a:hover {
  text-decoration: underline;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar,
.scrollbar_x,
.scrollbar_y {
  /* Handle */
  /* Handle on hover */
}
.scrollbar::-webkit-scrollbar,
.scrollbar_x::-webkit-scrollbar,
.scrollbar_y::-webkit-scrollbar {
  width: 8px;
}
.scrollbar::-webkit-scrollbar-thumb,
.scrollbar_x::-webkit-scrollbar-thumb,
.scrollbar_y::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.scrollbar::-webkit-scrollbar-thumb:hover,
.scrollbar_x::-webkit-scrollbar-thumb:hover,
.scrollbar_y::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar_x {
  overflow-y: hidden; /* Hide vertical scrollbar */
}
.scrollbar_y {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.highcharts-root {
  transition: 0.3s all;
}
.highcharts-root .highcharts-axis-labels,
.highcharts-root text {
  fill: theme("colors.theme.base");
  color: theme("colors.theme.base");
  font-size: 0.75rem;
}
.highcharts-root, .highcharts-background, .highcharts-scrollable-mask {
  fill: theme("colors.theme.containerBg");
}
.highcharts-button {
  cursor: pointer;
}
.highcharts-button-box {
  stroke: theme("colors.theme.muted");
}
.highcharts-legend-item {
  fill: theme("colors.theme.punched");
  color: theme("colors.theme.punched");
  cursor: pointer;
  font-size: 0.9rem;
}
.highcharts-legend-item-hidden {
  opacity: 0.4;
}
.highcharts-legend-item:hover {
  font-weight: bold;
}
.highcharts-tracker-line {
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.highcharts-color-0 .highcharts-area, .highcharts-color-0 .highcharts-point, .highcharts-color-0 .highcharts-halo {
  fill: #7cb5ec;
}
.highcharts-color-0 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-0 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-0 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-0 .highcharts-graph, .highcharts-color-0 .highcharts-label-box, .highcharts-color-0 .highcharts-tooltip-box {
  stroke: #7cb5ec;
}
.highcharts-color-0 .highcharts-color-0 {
  fill: #7cb5ec;
}
.highcharts-color-0 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-1 .highcharts-area, .highcharts-color-1 .highcharts-point, .highcharts-color-1 .highcharts-halo {
  fill: #f15c80;
}
.highcharts-color-1 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-1 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-1 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-1 .highcharts-graph, .highcharts-color-1 .highcharts-label-box, .highcharts-color-1 .highcharts-tooltip-box {
  stroke: #f15c80;
}
.highcharts-color-1 .highcharts-color-1 {
  fill: #f15c80;
}
.highcharts-color-1 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-2 .highcharts-area, .highcharts-color-2 .highcharts-point, .highcharts-color-2 .highcharts-halo {
  fill: #90ed7d;
}
.highcharts-color-2 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-2 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-2 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-2 .highcharts-graph, .highcharts-color-2 .highcharts-label-box, .highcharts-color-2 .highcharts-tooltip-box {
  stroke: #90ed7d;
}
.highcharts-color-2 .highcharts-color-2 {
  fill: #90ed7d;
}
.highcharts-color-2 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-3 .highcharts-area, .highcharts-color-3 .highcharts-point, .highcharts-color-3 .highcharts-halo {
  fill: #f7a35c;
}
.highcharts-color-3 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-3 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-3 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-3 .highcharts-graph, .highcharts-color-3 .highcharts-label-box, .highcharts-color-3 .highcharts-tooltip-box {
  stroke: #f7a35c;
}
.highcharts-color-3 .highcharts-color-3 {
  fill: #f7a35c;
}
.highcharts-color-3 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-4 .highcharts-area, .highcharts-color-4 .highcharts-point, .highcharts-color-4 .highcharts-halo {
  fill: #8085e9;
}
.highcharts-color-4 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-4 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-4 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-4 .highcharts-graph, .highcharts-color-4 .highcharts-label-box, .highcharts-color-4 .highcharts-tooltip-box {
  stroke: #8085e9;
}
.highcharts-color-4 .highcharts-color-4 {
  fill: #8085e9;
}
.highcharts-color-4 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-5 .highcharts-area, .highcharts-color-5 .highcharts-point, .highcharts-color-5 .highcharts-halo {
  fill: #5c5cf1;
}
.highcharts-color-5 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-5 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-5 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-5 .highcharts-graph, .highcharts-color-5 .highcharts-label-box, .highcharts-color-5 .highcharts-tooltip-box {
  stroke: #5c5cf1;
}
.highcharts-color-5 .highcharts-color-5 {
  fill: #5c5cf1;
}
.highcharts-color-5 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-6 .highcharts-area, .highcharts-color-6 .highcharts-point, .highcharts-color-6 .highcharts-halo {
  fill: #e4d354;
}
.highcharts-color-6 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-6 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-6 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-6 .highcharts-graph, .highcharts-color-6 .highcharts-label-box, .highcharts-color-6 .highcharts-tooltip-box {
  stroke: #e4d354;
}
.highcharts-color-6 .highcharts-color-6 {
  fill: #e4d354;
}
.highcharts-color-6 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-7 .highcharts-area, .highcharts-color-7 .highcharts-point, .highcharts-color-7 .highcharts-halo {
  fill: #2b908f;
}
.highcharts-color-7 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-7 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-7 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-7 .highcharts-graph, .highcharts-color-7 .highcharts-label-box, .highcharts-color-7 .highcharts-tooltip-box {
  stroke: #2b908f;
}
.highcharts-color-7 .highcharts-color-7 {
  fill: #2b908f;
}
.highcharts-color-7 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-8 .highcharts-area, .highcharts-color-8 .highcharts-point, .highcharts-color-8 .highcharts-halo {
  fill: #f45b5b;
}
.highcharts-color-8 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-8 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-8 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-8 .highcharts-graph, .highcharts-color-8 .highcharts-label-box, .highcharts-color-8 .highcharts-tooltip-box {
  stroke: #f45b5b;
}
.highcharts-color-8 .highcharts-color-8 {
  fill: #f45b5b;
}
.highcharts-color-8 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-color-9 .highcharts-area, .highcharts-color-9 .highcharts-point, .highcharts-color-9 .highcharts-halo {
  fill: #91e8e1;
}
.highcharts-color-9 .highcharts-area {
  opacity: 0.15;
}
.highcharts-color-9 .highcharts-point.highcharts-point-hover {
  stroke: #ffffff;
}
.highcharts-color-9 .highcharts-halo {
  fill-opacity: 0.25;
}
.highcharts-color-9 .highcharts-graph, .highcharts-color-9 .highcharts-label-box, .highcharts-color-9 .highcharts-tooltip-box {
  stroke: #91e8e1;
}
.highcharts-color-9 .highcharts-color-9 {
  fill: #91e8e1;
}
.highcharts-color-9 .highcharts-graph {
  stroke-width: 2;
}
.highcharts-shadow {
  stroke: #000000;
  stroke-width: 1;
  stroke-opacity: 0.4;
}
.highcharts-yaxis-grid .highcharts-grid-line {
  stroke-dasharray: 1, 20;
  stroke: theme("colors.theme.muted");
}
.highcharts-series {
  transition: 0.35s stroke-width;
}
.highcharts-series-hover .highcharts-graph {
  stroke-width: 3;
}
.highcharts-selection-marker {
  fill: theme("colors.theme.highlighted");
  opacity: 0.2;
}
.highcharts-plot-border, .highcharts-series-group {
  fill: none;
}
.highcharts-series-inactive {
  opacity: 0.25;
}
.highcharts-visually-hidden {
  @apply sr-only;
}

*,
::after,
::before {
  box-sizing: border-box;
}

/**
  * 1. Change the inconsistent appearance in all browsers (opinionated).
  * 2. Add typography inheritance in all browsers (opinionated).
*/
button,
input,
select,
textarea {
  padding: 0.25em 0.375em; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  background-color: transparent; /* 1 */
  border: 1px solid WindowFrame; /* 1 */
}

/**
* Change the inconsistent appearance in all browsers (opinionated).
*/
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat right center/1em;
  border-radius: 0;
  padding-right: 1em;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button {
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;
}

img {
  width: 100%;
  max-width: 100%;
}

svg {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.1;
}

body {
  margin: 0;
  font-family: "inter", "Helvetica Neue", sans-serif;
  color: var(--color-text-base);
}