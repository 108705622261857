// --------------------- ===
//  CUSTOM LAYERS
// ---------------------
@layer base {
  [type='text'],
  [type='password'],
  [type='email'],
  [type='number'],
  [type='url'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='week'],
  [type='time'],
  [type='search'],
  [type='tel'],
  [type='checkbox'],
  [type='radio'],
  select,
  select[multiple],
  textarea {
    @apply border border-theme-fieldBorder bg-theme-fieldBg p-2 rounded-innerBox text-theme-base;

    &:disabled {
      @apply border-0 opacity-50;
    }

    &:focus,
    &:focus-within {
      &:not(:disabled) {
        @apply border-theme-fieldBorder bg-theme-bg;
      }
    }

    &::placeholder,
    &.invalid {
      // .invalid helps styling for select
      @apply text-theme-muted;
    }
  }
  select {
    cursor: pointer;
  }
}

@layer components {
  .divider {
    @apply border-dotted border-theme-fieldBorder;
  }
  .divider-b {
    @apply divider border-b;
  }
  .divider-t {
    @apply divider border-t;
  }
  .divider-l {
    @apply divider border-l;
  }
  .divider-r {
    @apply divider border-r;
  }
}

@layer utilities {
  // .filter-none {
  //   filter: none;
  // }
  // .filter-grayscale {
  //   filter: grayscale(100%);
  // }
}
