@tailwind base; // https://unpkg.com/tailwindcss@3.1.6/src/css/preflight.css
@tailwind components;
@tailwind utilities;

@import './lib/twLayers';

@import './lib/colorsArchive';
@import './lib/themeColors';
@import './lib/mixins';
@import './lib/rootVars';
@import './lib/variablesArchive';
@import './lib/variables';
@import './lib/fonts';
@import './lib/normalize';
@import './lib/highCharts';
