// --------------------- ===
//  VARS
// ---------------------
$high-colors: #7cb5ec, #f15c80, #90ed7d, #f7a35c, #8085e9, #5c5cf1, #e4d354,
  #2b908f, #f45b5b, #91e8e1;

// --------------------- ===
//  HIGH CHARTS
// ---------------------
.highcharts- {
  &root {
    transition: 0.3s all;
    .highcharts-axis-labels,
    text {
      fill: theme('colors.theme.base');
      color: theme('colors.theme.base');
      font-size: 0.75rem;
    }
  }

  &root,
  &background,
  &scrollable-mask {
    fill: theme('colors.theme.containerBg');
  }

  &button {
    cursor: pointer;
  }

  &button-box {
    stroke: theme('colors.theme.muted');
  }

  // &axis-labels,
  // &tooltip text,
  // &label text {
  //   fill: theme('colors.theme.base');
  //   color: theme('colors.theme.base');
  //   font-size: 0.75rem;
  // }

  &legend-item {
    fill: theme('colors.theme.punched');
    color: theme('colors.theme.punched');
    cursor: pointer;
    font-size: 0.9rem;

    &-hidden {
      opacity: 0.4;
    }

    &:hover {
      font-weight: bold;
    }
  }

  &tracker-line {
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }

  @each $color in $high-colors {
    $i: index($high-colors, $color) - 1;
    &color-#{$i} {
      .highcharts- {
        &area,
        &point,
        &halo {
          fill: $color;
        }

        &area {
          opacity: 0.15;
        }

        &point {
          &.highcharts-point-hover {
            stroke: #ffffff;
          }
        }

        &halo {
          fill-opacity: 0.25;
        }

        &graph,
        &label-box,
        &tooltip-box {
          stroke: $color;
        }

        &color-#{$i} {
          fill: $color; // dot on tooltip - can remove if issue
        }

        &graph {
          stroke-width: 2;
        }
      }
    }
  }

  &shadow {
    stroke: #000000;
    stroke-width: 1;
    stroke-opacity: 0.4;
  }

  &yaxis-grid {
    .highcharts-grid-line {
      stroke-dasharray: 1, 20;
      stroke: theme('colors.theme.muted');
    }
  }

  &series {
    transition: 0.35s stroke-width;
    &-hover {
      .highcharts-graph {
        stroke-width: 3;
      }
    }
  }

  &selection-marker {
    fill: theme('colors.theme.highlighted');
    opacity: 0.2;
  }

  &plot-border,
  &series-group {
    fill: none;
  }

  &series-inactive {
    opacity: 0.25;
  }

  &visually-hidden {
    @apply sr-only;
  }
}
