// TODO integrate into shared

*,
::after,
::before {
  box-sizing: border-box;
}

/**
  * 1. Change the inconsistent appearance in all browsers (opinionated).
  * 2. Add typography inheritance in all browsers (opinionated).
*/
button,
input,
select,
textarea {
  padding: 0.25em 0.375em; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  background-color: transparent; /* 1 */
  border: 1px solid WindowFrame; /* 1 */
}

/**
* Change the inconsistent appearance in all browsers (opinionated).
*/

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat right center / 1em;
  border-radius: 0;
  padding-right: 1em;
}

// Correct the inability to style buttons in iOS and Safari.
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button {
  padding: 0; // Safari on ipad fix
  margin: 0; //Safari
  background: none;
  border: 0;
  cursor: pointer;
}

img {
  width: 100%;
  max-width: 100%;
}

svg {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
}

// TYPOGRAPHY
// Tags are for screenreaders and search engines,
// styling is for visual users
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  margin-top: 0;
  margin-bottom: 0;
  font-size: map-get($font-sizes, 'md');
  line-height: 1.1;
}

// EXTRAS
body {
  margin: 0;
  font-family: $base-font-family;
  color: var(--color-text-base);
}
