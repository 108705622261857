/* --------------------- ===
  FONTS
---------------------------*/
// IMPORT
// @import url('https://fonts.googleapis.com/css?family=Lato:400,900');

// or FONT FACE
// @font-face {
//   font-family: 'someFont';
//   src:
//     url('$fonts/some-font.woff2') format('woff2'),
//     url('$fonts/some-font.woff') format('woff');
// }

// @font-face {
//   font-family: 'exo';
//   src: url('~libs/shared/assets/src/fonts/exo/Exo-VariableFont_wght.ttf')
//     format('truetype');
//   font-weight: 100 1000;
// }

// @font-face {
//   font-family: 'poppins';
//   src: url('~libs/shared/assets/src/fonts/poppins/Poppins-Light.ttf')
//     format('truetype');
//   font-weight: 100 200 300 400;
// }

// @font-face {
//   font-family: 'poppins';
//   src: url('~libs/shared/assets/src/fonts/poppins/Poppins-Medium.ttf')
//     format('truetype');
//   font-weight: 500;
// }

// @font-face {
//   font-family: 'poppins';
//   src: url('~libs/shared/assets/src/fonts/poppins/Poppins-SemiBold.ttf')
//     format('truetype');
//   font-weight: 600;
// }

@font-face {
  font-family: 'inter';
  // src: url('~libs/shared/assets/src/fonts/Inter-VariableFont_slnt_wght.ttf')
  // format('truetype');
  src: url('/assets/Inter-VariableFont_slnt_wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-display: swap;
}

@font-face {
  font-family: 'Transducer';
  src: url('/assets/transducer-font/TransducerTest-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Transducer';
  src: url('/assets/transducer-font/TransducerTest-Bold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
